<template>
  <div class="align-center d-flex fill-height flex-column justify-center" style="row-gap: 50px; font-size: xx-large;">
    <div v-if="loading">
      {{ $t('Wait for the redirection..') }}
      <v-progress-circular size="100" indeterminate color="primary" />
    </div>
    <div v-else>
      {{ $t('An error occurred during login. Redirecting...') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TokenLoginPage',
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    const { name, id, token } = this.$route.query;
    console.log(name, id, token)

    if (!name || !id || !token) {
      this.loading = false;
      this.$error("Missing name, id, or token in query parameters")
    } else {
      await this.handleTokenLogin(name, id, token);
    }
  },
  methods: {
    async handleTokenLogin(name, id, token) {
      try {
        const response = await this.$axios.get(`/users/token-login`, {
          params: { name, id, token },
        });
        
        if (response && response.status === 200 && response.data) {
          this.$store.dispatch('login', response.data);
          this.$createLoginLog(response.data.user, "token")
          this.$router.push("/films");
        } else {
          this.$error('Login failed: Invalid response from server')
        }
      } catch (error) {
        this.$error(error)
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
