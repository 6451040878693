<template>
  <SearchComponent />
</template>

<script>

import SearchComponent from '@/components/SearchComponent.vue'
export default {
  name: 'SearchPage',
  components:{
    SearchComponent
  }
}
</script>
